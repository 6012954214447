import { cn } from '@/utils/cn'

export default function EbayIcon({
  size = 32,
  title = 'Ebay',
  className = '',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 800.00 800.00"
      width={size}
      height={size}
      role="img"
      aria-labelledby="ebayTitle"
      className={cn('fill-primary', className)}
    >
      <title id="ebayTitle">{title}</title>
      <path
        fill="current-color"
        d="
    M 402.96 428.35
    Q 400.07 436.64 399.33 438.30
    Q 383.22 474.10 346.15 482.03
    Q 322.32 487.13 298.51 479.31
    C 287.78 475.78 278.00 469.64 270.66 461.55
    Q 268.93 459.63 267.95 458.01
    Q 267.18 456.74 267.16 458.22
    Q 266.95 469.08 265.91 477.72
    Q 265.85 478.25 265.30 478.25
    L 233.01 478.25
    A 0.22 0.21 1.2 0 1 232.79 478.03
    C 233.88 456.88 233.73 440.04 233.63 410.67
    A 0.43 0.42 89.3 0 0 233.21 410.25
    L 101.99 410.25
    Q 101.05 410.25 101.06 411.19
    C 101.37 431.60 112.08 449.19 131.11 456.69
    Q 145.31 462.29 161.89 459.59
    C 177.00 457.14 189.61 448.40 195.24 434.25
    Q 195.44 433.75 195.98 433.75
    L 229.30 433.75
    Q 230.04 433.75 229.90 434.48
    C 225.86 454.78 211.51 469.77 192.25 477.01
    Q 182.85 480.54 174.14 481.85
    C 152.13 485.17 128.94 483.78 108.83 475.67
    Q 92.35 469.02 81.49 454.82
    C 73.25 444.05 68.79 429.52 67.39 415.87
    C 65.68 399.13 66.39 382.34 71.86 366.08
    Q 80.05 341.72 101.97 329.21
    Q 113.00 322.91 127.35 320.09
    Q 144.33 316.76 162.77 318.19
    C 176.00 319.22 190.23 323.07 201.02 330.01
    C 220.99 342.85 231.71 363.81 233.32 387.43
    Q 233.33 387.58 233.47 387.55
    L 233.48 387.55
    Q 233.59 387.53 233.59 387.35
    L 233.57 256.96
    A 0.21 0.21 0.0 0 1 233.78 256.75
    L 266.98 256.75
    Q 267.45 256.75 267.45 257.23
    L 267.45 343.36
    Q 267.45 344.30 267.99 343.53
    Q 271.85 338.00 277.54 333.56
    Q 288.15 325.28 300.74 321.87
    C 315.45 317.88 331.68 317.07 346.72 320.34
    Q 378.73 327.29 394.97 355.24
    C 405.25 372.93 408.41 395.60 405.44 415.53
    Q 405.42 415.71 405.45 415.73
    Q 405.52 415.76 405.59 415.74
    Q 405.71 415.70 405.73 415.64
    Q 408.66 407.34 415.17 401.15
    Q 422.61 394.07 431.79 390.29
    Q 444.96 384.88 461.02 382.98
    Q 473.73 381.49 486.50 381.49
    Q 523.71 381.50 526.92 381.47
    Q 527.45 381.47 527.47 380.95
    Q 527.95 370.51 526.00 364.27
    Q 523.10 355.03 515.67 349.61
    Q 508.73 344.56 500.03 342.74
    Q 486.54 339.92 473.33 342.58
    C 461.61 344.94 451.25 352.20 448.85 364.51
    Q 448.75 365.00 448.25 365.00
    L 414.22 365.00
    A 0.56 0.56 0.0 0 1 413.66 364.39
    Q 414.45 354.17 419.95 345.43
    Q 426.46 335.08 437.30 329.02
    Q 448.96 322.50 462.17 320.10
    Q 480.04 316.85 497.39 318.03
    C 512.63 319.07 527.00 322.09 539.31 330.35
    Q 545.96 334.81 551.39 342.42
    Q 551.41 342.45 551.55 342.36
    Q 551.68 342.28 551.60 342.13
    L 542.74 324.59
    A 0.23 0.23 0.0 0 1 542.94 324.26
    L 581.58 324.26
    Q 582.00 324.26 582.18 324.64
    L 638.42 443.26
    A 0.26 0.26 0.0 0 0 638.89 443.26
    L 694.92 324.66
    A 0.65 0.64 11.9 0 1 695.51 324.28
    L 730.44 324.28
    A 0.28 0.27 -76.5 0 1 730.68 324.68
    L 628.18 536.54
    Q 627.95 537.00 627.44 537.00
    L 590.81 537.00
    A 0.21 0.21 0.0 0 1 590.62 536.69
    L 619.96 478.25
    Q 620.21 477.76 619.95 477.26
    L 558.16 355.10
    Q 558.11 355.00 557.97 355.05
    L 557.96 355.05
    Q 557.82 355.09 557.87 355.25
    Q 561.50 366.70 561.48 378.75
    Q 561.47 382.39 561.45 453.25
    C 561.45 461.59 561.86 468.06 562.43 477.76
    Q 562.46 478.25 561.97 478.25
    L 531.91 478.25
    A 0.59 0.59 0.0 0 1 531.32 477.68
    L 530.58 456.71
    Q 530.56 456.07 530.18 456.59
    C 529.06 458.18 527.51 460.27 526.27 461.54
    C 515.21 472.83 501.77 479.51 486.34 482.33
    Q 465.34 486.16 446.11 482.34
    Q 433.60 479.85 422.65 472.30
    C 407.99 462.18 402.04 445.77 403.16 428.39
    Q 403.20 427.65 402.96 428.35
    Z
    M 101.76 386.30
    A 0.45 0.44 -90.0 0 0 102.20 386.75
    L 197.97 386.75
    Q 198.44 386.75 198.43 386.28
    Q 197.86 361.57 179.25 349.23
    Q 171.10 343.81 160.70 342.22
    C 132.52 337.90 101.74 354.68 101.76 386.30
    Z
    M 267.36 400.51
    C 267.06 431.38 285.34 459.28 318.77 459.60
    C 352.20 459.93 371.02 432.39 371.33 401.52
    C 371.63 370.66 353.35 342.76 319.91 342.43
    C 286.48 342.11 267.66 369.64 267.36 400.51
    Z
    M 527.52 404.90
    A 0.37 0.36 -90.0 0 0 527.16 404.53
    Q 509.64 404.31 491.05 405.02
    Q 478.67 405.49 466.07 407.41
    Q 455.48 409.02 447.34 414.25
    Q 440.07 418.92 438.59 426.76
    Q 436.30 438.96 442.75 448.34
    C 446.74 454.14 454.87 458.69 461.70 459.85
    Q 475.94 462.26 489.12 459.65
    C 510.49 455.42 524.85 440.57 527.28 418.33
    C 527.71 414.43 527.49 409.66 527.52 404.90
    Z"
      />
    </svg>
  )
}
