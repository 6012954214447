import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

export default function useSidebar() {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    'collapsed-sidebar',
    false,
  )

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 768 ? false : isCollapsed)
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isCollapsed, setIsCollapsed])

  return [isCollapsed, setIsCollapsed] as const
}
