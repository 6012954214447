import { Navigate } from 'react-router-dom'

import { useUser } from '@/lib/auth'
import { getDefaultRoute } from '@/lib/authorization'

export const DefaultRoute = ({ redirectTo }: { redirectTo?: string }) => {
  const { data: user } = useUser()
  const to = redirectTo || getDefaultRoute(user?.roles || [])

  return <Navigate to={to} replace />
}
