'use client'

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  useToast,
} from '@/components/ui/toast'

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider duration={1500}>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        let defaultTitle = ''
        if (!title) {
          if (props.variant === 'success') {
            defaultTitle = 'Success'
          } else if (props.variant === 'info') {
            defaultTitle = 'Info'
          } else if (props.variant === 'warning') {
            defaultTitle = 'Warning'
          } else if (props.variant === 'error') {
            defaultTitle = 'Error'
          }
        }

        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {(title || defaultTitle) && (
                <ToastTitle>{title || defaultTitle}</ToastTitle>
              )}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
