import { useContext, createContext } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import dayjs from './dayjs'

type TimeZoneProviderState = {
  timeZone: string
  setTimeZone: (timeZone: string) => void
  newDate: (date?: string | Date) => Date
  startOfDay: (date: string | Date) => string
  endOfDay: (date: string | Date) => string
  subDays: (date: string | Date, days: number) => string
  format: (date: string | Date, format?: string) => string
}

const TimeZoneContext = createContext<TimeZoneProviderState | undefined>(
  undefined,
)

const TimeZoneProvider = ({ children }: { children: React.ReactNode }) => {
  const [timeZone, setTimeZone] = useLocalStorage(
    'time-zone',
    'America/Los_Angeles',
  )

  const newDate = (date?: string | Date) => {
    return dayjs(date).tz(timeZone).toDate()
  }

  const startOfDay = (date: string | Date) => {
    return dayjs(date).tz(timeZone).startOf('day').toISOString()
  }

  const endOfDay = (date: string | Date) => {
    return dayjs(date).tz(timeZone).endOf('day').toISOString()
  }

  const subDays = (date: string | Date, days: number) => {
    return dayjs(date).tz(timeZone).subtract(days, 'day').toISOString()
  }

  const format = (
    date: string | Date | dayjs.Dayjs,
    format = 'MMM DD, YYYY HH:mm',
  ): string => {
    return dayjs(date).tz(timeZone).format(format)
  }

  const value = {
    timeZone,
    setTimeZone,
    newDate,
    startOfDay,
    endOfDay,
    subDays,
    format,
  }

  return (
    <TimeZoneContext.Provider value={value}>
      {children}
    </TimeZoneContext.Provider>
  )
}

const useTimeZone = () => {
  const context = useContext(TimeZoneContext)
  if (!context) {
    throw new Error('useTimeZone must be used within a TimeZoneProvider')
  }
  return context
}

// eslint-disable-next-line react-refresh/only-export-components
export { TimeZoneProvider, useTimeZone }
