import { cn } from '@/utils/cn'

export default function LogoIcon({
  size = 32,
  title = 'Logo',
  className = '',
  twoTone = false,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 32.00 32.00"
      width={size}
      height={size}
      role="img"
      aria-labelledby="logoTitle"
      className={cn('fill-red', className)}
    >
      <title id="logoTitle">{title}</title>
      <path
        stroke="#751b1f"
        strokeWidth="2.00"
        strokeLinecap="butt"
        vectorEffect="non-scaling-stroke"
        d="
    M 9.12 12.58
    Q 7.58 15.26 7.55 19.75"
      />
      <path
        className={cn(twoTone ? 'fill-red' : 'fill-primary')}
        d="
    M 9.12 12.58
    Q 7.58 15.26 7.55 19.75
    Q 3.89 15.35 5.35 10.37
    Q 7.57 2.81 16.11 2.67
    Q 16.43 2.67 16.43 2.35
    Q 16.43 2.23 16.36 2.10
    Q 16.30 1.97 16.18 1.91
    Q 15.34 1.50 14.79 0.83
    Q 14.18 0.10 15.07 0.44
    Q 19.64 2.20 23.58 1.47
    Q 24.15 1.37 24.49 1.83
    Q 25.11 2.65 26.37 2.66
    Q 26.99 2.67 26.92 3.28
    C 26.34 8.10 21.10 7.24 17.66 7.13
    Q 14.13 7.01 12.46 7.92
    Q 9.98 9.26 9.12 12.58
    Z"
      />
      <path
        className="fill-primary"
        d="
    M 15.18 9.68
    A 1.40 1.40 0.0 0 1 17.16 9.65
    L 17.82 10.29
    A 3.94 3.73 44.1 0 1 18.05 15.71
    L 17.90 15.87
    A 3.94 3.73 44.1 0 1 12.48 15.80
    L 11.82 15.16
    A 1.40 1.40 0.0 0 1 11.78 13.18
    L 15.18 9.68
    Z"
      />
      <path
        className="fill-primary"
        d="
    M 7.55 19.75
    Q 7.58 15.26 9.12 12.58
    Q 9.67 16.41 11.85 17.89
    C 17.01 21.38 23.54 15.33 19.94 9.91
    A 0.37 0.36 -16.9 0 1 20.25 9.34
    L 23.75 9.34
    A 1.54 1.53 -2.4 0 1 25.28 10.74
    Q 26.06 19.85 18.63 22.78
    Q 15.84 23.88 12.79 23.11
    Q 9.51 22.28 7.55 19.75
    Z"
      />
      <path
        className="fill-primary"
        d="
    M 16.47 32.00
    L 14.02 32.00
    Q 7.88 30.95 5.28 24.85
    A 0.47 0.47 0.0 0 1 5.71 24.20
    L 9.49 24.20
    Q 10.03 24.20 10.40 24.60
    Q 15.24 29.82 20.05 24.57
    Q 20.40 24.19 20.91 24.19
    L 24.68 24.19
    Q 25.26 24.19 25.07 24.73
    Q 22.97 30.80 16.47 32.00
    Z"
      />
    </svg>
  )
}
