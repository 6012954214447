import { capitalize } from 'lodash'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command'
import { useNav } from '@/hooks/use-nav'

import type { NavLink } from '@/types/nav'

export function CommandMenu() {
  const [open, setOpen] = React.useState(false)

  const navigate = useNavigate()

  const { modules } = useNav()

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  function renderModuleItems(items: NavLink[]) {
    const renderItem = (item: NavLink, parentTitle?: string) => {
      const result: React.ReactNode[] = []

      if (!item.items || item.items.length === 0) {
        result.push(
          <CommandItem
            key={item.title}
            onSelect={() => {
              navigate(item.to)
              setOpen(false)
            }}
          >
            {item.icon}
            <span className="ml-2 capitalize">
              {parentTitle ? `${parentTitle} - ${item.title}` : item.title}
            </span>
          </CommandItem>,
        )
      }

      if (item.items) {
        result.push(
          ...item.items.flatMap((child) => renderItem(child, item.title)),
        )
      }

      return result
    }

    return items.flatMap((item) => renderItem(item))
  }

  return (
    <>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <Command>
          <CommandInput placeholder="Type a command or search..." />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            {modules.map((m) => (
              <React.Fragment key={m.name}>
                <CommandGroup heading={capitalize(m.name)}>
                  {renderModuleItems(m.items)}
                </CommandGroup>
                <CommandSeparator />
              </React.Fragment>
            ))}
          </CommandList>
        </Command>
      </CommandDialog>
    </>
  )
}
