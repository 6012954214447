import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'

import { AuthLoader } from '@/components/auth-loader'
import { Toaster as SonnerToaster } from '@/components/ui/sonner'
import { Spinner } from '@/components/ui/spinner'
import { Toaster } from '@/components/ui/toast/toaster'
import { TooltipProvider } from '@/components/ui/tooltip'
import { queryClient } from '@/lib/react-query'
import { TimeZoneProvider } from '@/lib/time-zone'

export const AppProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <Spinner size="lg" />
        </div>
      }
    >
      <HelmetProvider>
        <TooltipProvider delayDuration={0}>
          <TimeZoneProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools buttonPosition="bottom-left" />
              <Toaster />
              <SonnerToaster />
              <AuthLoader
                renderLoading={() => (
                  <div className="flex h-screen w-screen items-center justify-center">
                    <Spinner size="lg" />
                  </div>
                )}
              >
                {children}
              </AuthLoader>
            </QueryClientProvider>
          </TimeZoneProvider>
        </TooltipProvider>
      </HelmetProvider>
    </React.Suspense>
  )
}
