import * as React from 'react'

import { VIEW_PERMS } from '@/config/permissions'

import { useUser } from './auth'

import type { Role } from '@/modules/roles/types'

const useAuthorization = () => {
  const { data: user } = useUser()

  if (!user) {
    throw Error('User does not exist!')
  }

  const checkAccess = ({
    allowedPermissions,
  }: {
    allowedPermissions: string[]
  }) => {
    if (allowedPermissions && allowedPermissions.length > 0) {
      return allowedPermissions.some((p) =>
        user.roles?.some((r) =>
          r.permissions?.some((rp) => rp.active && rp.value === p),
        ),
      )
    }

    return true
  }

  return {
    checkAccess,
  }
}

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode
  children: React.ReactNode
} & (
  | {
      allowedPermissions: string[]
      policyCheck?: never
    }
  | {
      allowedPermissions?: never
      policyCheck: boolean
    }
)

const Authorization = ({
  policyCheck,
  allowedPermissions,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization()

  let canAccess = false

  if (allowedPermissions) {
    canAccess = checkAccess({ allowedPermissions })
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck
  }

  return <>{canAccess ? children : forbiddenFallback}</>
}

const getDefaultRoute = (roles: Role[]) => {
  const allPermissions = roles.flatMap(
    (role) => role.permissions?.map((p) => p.value) || [],
  )

  const piority = [
    'VIEW_ORDER',
    'VIEW_SCALE',
    'VIEW_QC',
    'VIEW_LISTING',
    'VIEW_ADMIN',
  ] as (keyof typeof VIEW_PERMS)[]

  for (const permission of piority) {
    if (
      allPermissions.includes(VIEW_PERMS[permission as keyof typeof VIEW_PERMS])
    ) {
      switch (permission as keyof typeof VIEW_PERMS) {
        case 'VIEW_ORDER':
          return '/sales/orders'
        case 'VIEW_LISTING':
          return '/sales/listings'
        case 'VIEW_ADMIN':
          return '/admin/users'
        case 'VIEW_SCALE':
          return '/fulfillment/scale'
        case 'VIEW_QC':
          return '/fulfillment/qc'
      }
    }
  }

  return '/'
}

// eslint-disable-next-line react-refresh/only-export-components
export { useAuthorization, Authorization, getDefaultRoute }
