import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  UseQueryOptions,
  QueryFunction,
  MutationFunction,
  UseMutationOptions,
  queryOptions,
} from '@tanstack/react-query'
import { z } from 'zod'

import { cookies } from '@/config/cookies'

import { api } from './api-client'

import type { User } from '@/modules/users/types/user'
import type { ApiResponse } from '@/types/api'

type AuthConfig<User, LoginCredentials> = {
  userKey?: QueryKey
  userFn: QueryFunction<User, QueryKey>
  loginFn: MutationFunction<User, LoginCredentials>
  logoutFn: () => void
}

function configureAuth<User, Error, LoginCredentials>(
  config: AuthConfig<User, LoginCredentials>,
) {
  const { userFn, loginFn, logoutFn, userKey = ['authenticated-user'] } = config

  const useUser = (
    options?: Omit<
      UseQueryOptions<User, Error, User, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery({
      queryKey: userKey,
      queryFn: userFn,
      ...options,
    })
  }

  const useLogin = (
    options?: Omit<
      UseMutationOptions<User, Error, LoginCredentials>,
      'mutationFn'
    >,
  ) => {
    const queryClient = useQueryClient()

    const setUser = (data: User) => queryClient.setQueryData(userKey, data)

    return useMutation({
      mutationFn: loginFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user)
        options?.onSuccess?.(user, ...rest)
      },
    })
  }

  const useLogout = () => {
    const queryClient = useQueryClient()

    return () => {
      queryClient.setQueryData(userKey, null)
      logoutFn()
    }
  }

  return {
    useUser,
    useLogin,
    useLogout,
  }
}

const getUser = () => {
  return api.get('profile') as ApiResponse<User>
}

const logout = () => {
  cookies.clearTokens()
}

export const loginSchema = z.object({
  email: z.string().min(1, 'Required').email('Invalid email'),
  password: z.string().min(5, 'Required'),
})
export type LoginDTO = z.infer<typeof loginSchema>

const loginWithEmailAndPassword = (data: LoginDTO) => {
  return api.post('auth/sign-in', data) as ApiResponse<User>
}

const authConfig = {
  userFn: async () => {
    const res = await getUser()
    return res.data ?? null
  },
  loginFn: async (data: LoginDTO) => {
    const res = await loginWithEmailAndPassword(data)
    return res.data
  },
  logoutFn: logout,
} satisfies AuthConfig<User, LoginDTO>

export const { useUser, useLogin, useLogout } = configureAuth(authConfig)

export const getProfileQueryOptions = () => {
  return queryOptions({
    queryKey: ['authenticated-user'],
    queryFn: async () => {
      const res = await getUser()
      return res.data ?? null
    },
  })
}
