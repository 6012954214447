import React from 'react'

import { useUser } from '@/lib/auth'

export function AuthLoader({
  children,
  renderLoading,
}: {
  children: React.ReactNode
  renderLoading: () => React.ReactNode
}) {
  const { isFetched } = useUser()

  if (!isFetched) {
    return renderLoading()
  }

  return <>{children}</>
}
