import { Navigate, useLocation } from 'react-router-dom'

import { useUser } from '@/lib/auth'

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { data: user } = useUser()

  const location = useLocation()

  if (!user) {
    return (
      <Navigate
        to={`/auth/login?redirectTo=${encodeURIComponent(location.pathname)}`}
        replace
      />
    )
  }

  return children
}
