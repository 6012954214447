import Cookies from 'js-cookie'

const prefix = '_gs'

export enum TOKENS {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
}

export const cookies = {
  getToken: (key: TOKENS) => Cookies.get(`${prefix}_${key}`),
  setToken: (key: TOKENS, value: string) =>
    Cookies.set(`${prefix}_${key}`, value, { expires: 7 }),
  clearTokens: () => {
    Cookies.remove(`${prefix}_${TOKENS.TOKEN}`)
    Cookies.remove(`${prefix}_${TOKENS.REFRESH_TOKEN}`)
  },
}
