import { RouterProvider } from 'react-router-dom'

import { AppProvider } from './app-provider'
import { createRouter } from './routes'
import { ThemeProvider } from './theme-provider'

const AppRouter = () => {
  const router = createRouter()

  return <RouterProvider router={router} />
}

function App() {
  return (
    <ThemeProvider defaultTheme="light" storageKey="gs-ui-theme">
      <AppProvider>
        <AppRouter />
      </AppProvider>
    </ThemeProvider>
  )
}

export default App
