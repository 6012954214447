import { IconMenu2, IconX, IconChevronsLeft } from '@tabler/icons-react'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogoIcon } from '@/assets/icons'
import { Layout } from '@/components/layouts/layout'
import { Button } from '@/components/ui/button'
import { useLogout, useUser } from '@/lib/auth'
import { cn } from '@/utils/cn'

import { Nav } from './nav'
import { ThemeSwitch } from './theme-switch'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu'

interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export function Sidebar({
  className,
  isCollapsed,
  setIsCollapsed,
}: SidebarProps) {
  const [navOpened, setNavOpened] = useState(false)

  /* Make body not scrollable when navBar is opened */
  useEffect(() => {
    if (navOpened) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [navOpened])

  return (
    <aside
      className={cn(
        'fixed left-0 right-0 top-0 z-50 w-full border-r-2 border-r-muted transition-[width] md:bottom-0 md:right-auto md:h-svh',
        isCollapsed ? 'md:w-11' : 'md:w-64',
        className,
      )}
    >
      {/* Overlay in mobile */}
      <div
        role="button"
        tabIndex={0}
        onClick={() => setNavOpened(false)}
        onKeyDown={() => setNavOpened((o) => !o)}
        className={cn(
          'absolute inset-0 w-full bg-black transition-opacity delay-100 duration-700 md:hidden',
          navOpened ? 'h-svh opacity-50' : 'h-0 opacity-0',
        )}
      />

      <Layout fixed className={cn('overflow-hidden', navOpened ? 'h-svh' : '')}>
        <Layout.Header
          sticky
          className="z-50 flex justify-between px-4 py-3 shadow-sm md:px-[10px]"
        >
          <LogoSection isCollapsed={isCollapsed} />

          {/* Toggle Button in mobile */}
          <Button
            variant="ghost"
            size="icon"
            className="md:hidden"
            aria-label="Toggle Navigation"
            aria-controls="sidebar-menu"
            aria-expanded={navOpened}
            onClick={() => setNavOpened((prev) => !prev)}
          >
            {navOpened ? <IconX /> : <IconMenu2 />}
          </Button>
        </Layout.Header>

        {/* Navigation links */}
        <Nav
          id="sidebar-menu"
          className={cn(
            'z-40 h-full flex-1 overflow-hidden',
            navOpened ? 'max-h-screen' : 'max-h-0 py-0 md:max-h-screen md:py-2',
          )}
          closeNav={() => setNavOpened(false)}
          isCollapsed={isCollapsed as boolean}
        />

        <Button
          onClick={() => setIsCollapsed(!isCollapsed)}
          size="icon"
          variant="outline"
          className="absolute -right-5 top-[85%] z-50 hidden rounded-full md:inline-flex"
        >
          <IconChevronsLeft
            className={`size-5 ${isCollapsed ? 'rotate-180' : ''}`}
          />
        </Button>
      </Layout>
    </aside>
  )
}

function LogoSection({ isCollapsed }: { isCollapsed: boolean }) {
  const { data: user } = useUser()
  const logout = useLogout()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return (
    <>
      <div>
        <LogoIcon className="aspect-square size-6" twoTone />
      </div>
      <div
        className={cn(
          isCollapsed
            ? 'invisible w-0'
            : 'visible w-auto flex flex-col justify-end truncate',
        )}
      >
        <span className="font-medium">GOD System</span>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span className="cursor-pointer text-xs transition hover:underline">
              {user?.fullName}
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent forceMount>
            <DropdownMenuGroup>
              {/* <DropdownMenuItem>Profile</DropdownMenuItem>
              <DropdownMenuItem>Settings</DropdownMenuItem> */}
              <DropdownMenuItem
                onSelect={() => {
                  navigate('/change-password')
                }}
              >
                Change Password
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => {
                logout()
                queryClient.clear()
              }}
            >
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div
        className={cn(
          'flex items-center',
          isCollapsed ? 'invisible w-0' : 'visible w-auto ml-auto',
        )}
      >
        <ThemeSwitch />
        {/* <TimeZoneSwitch /> */}
      </div>
    </>
  )
}
