import nProgress from 'nprogress'
import { useEffect } from 'react'
import { useNavigation } from 'react-router-dom'

import useSidebar from '@/hooks/use-sidebar'
import { cn } from '@/utils/cn'

import { CommandMenu } from '../command-menu'
import { Sidebar } from '../sidebar'

const useNProgress = () => {
  const { state, location } = useNavigation()

  useEffect(() => {
    if (state !== 'idle') {
      nProgress.start()
    } else {
      nProgress.done()
    }

    return () => {
      if (state !== 'idle') {
        nProgress.done()
      }
    }
  }, [state, location?.pathname])
}

export function AppShell({ children }: { children: React.ReactNode }) {
  const [isCollapsed, setIsCollapsed] = useSidebar()

  useNProgress()

  return (
    <>
      <div className="relative h-full overflow-hidden">
        <Sidebar
          isCollapsed={isCollapsed as boolean}
          setIsCollapsed={setIsCollapsed}
        />
        <main
          id="content"
          className={cn(
            'h-full overflow-x-hidden pt-16 transition-[margin] md:overflow-y-hidden md:pt-0',
            isCollapsed ? 'md:ml-14' : 'md:ml-64',
          )}
        >
          {children}
        </main>
      </div>
      <CommandMenu />
    </>
  )
}
