import { IconLoader2 } from '@tabler/icons-react'

import { cn } from '@/utils/cn'

const sizes = {
  sm: 'h-4 w-4',
  md: 'h-8 w-8',
  lg: 'h-16 w-16',
  xl: 'h-24 w-24',
}

const variants = {
  light: 'text-white',
  primary: 'text-slate-600',
}

export type SpinnerProps = {
  size?: keyof typeof sizes
  variant?: keyof typeof variants
  className?: string
}

export const Spinner = ({
  size = 'md',
  variant = 'primary',
  className = '',
}: SpinnerProps) => {
  return (
    <>
      <IconLoader2
        className={cn(
          'animate-spin',
          sizes[size],
          variants[variant],
          className,
        )}
      />
      <span className="sr-only">Loading</span>
    </>
  )
}
