import { cn } from '@/utils/cn'

export default function ShopifyIcon({
  size = 32,
  title = 'Shopify',
  className = '',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 800.00 800.00"
      width={size}
      height={size}
      role="img"
      aria-labelledby="shopifyTitle"
      className={cn('fill-primary', className)}
    >
      <title id="shopifyTitle">{title}</title>
      <path
        fill="current-color"
        d="
    M 478.05 177.74
    A 0.28 0.28 0.0 0 0 478.40 177.92
    L 496.68 172.36
    Q 497.87 171.99 497.82 173.24
    L 474.87 698.09
    Q 474.86 698.25 474.71 698.22
    L 137.73 635.02
    Q 137.23 634.93 137.30 634.42
    Q 165.98 413.32 184.00 278.53
    C 184.57 274.30 186.19 270.35 190.23 268.21
    Q 193.05 266.71 196.76 265.51
    Q 223.33 256.91 255.46 247.26
    Q 255.93 247.11 256.04 246.63
    Q 264.85 209.18 282.79 175.05
    Q 292.63 156.33 306.17 140.18
    Q 317.10 127.15 331.40 117.42
    Q 347.91 106.20 367.80 102.86
    Q 376.50 101.41 384.28 101.93
    C 395.28 102.66 405.76 110.14 412.57 118.60
    Q 412.92 119.03 413.47 119.02
    Q 437.96 118.38 454.62 136.30
    Q 464.08 146.48 470.42 159.44
    Q 474.53 167.84 478.05 177.74
    Z
    M 389.02 125.87
    A 0.31 0.31 0.0 0 0 389.05 125.34
    C 383.41 121.61 378.01 122.07 371.19 122.83
    Q 367.13 123.29 363.56 124.31
    Q 349.51 128.33 338.30 137.28
    Q 331.35 142.83 326.92 147.67
    Q 314.10 161.64 304.62 178.88
    Q 288.94 207.39 280.12 238.72
    A 0.41 0.41 0.0 0 0 280.63 239.22
    L 326.56 225.24
    Q 327.02 225.10 327.12 224.63
    Q 327.57 222.62 329.05 215.76
    C 334.05 192.58 343.40 169.81 358.16 151.17
    Q 370.87 135.13 389.02 125.87
    Z
    M 424.40 142.76
    Q 429.41 159.32 430.11 180.75
    Q 430.19 183.22 430.15 192.16
    A 0.46 0.46 0.0 0 0 430.74 192.60
    L 458.40 184.18
    Q 458.93 184.02 458.77 183.49
    Q 454.42 169.26 446.96 157.27
    C 444.47 153.27 440.74 148.87 437.49 146.25
    Q 431.41 141.33 424.33 140.05
    A 0.53 0.53 0.0 0 0 423.74 140.75
    Q 423.99 141.42 424.40 142.76
    Z
    M 351.54 216.89
    L 409.46 199.27
    Q 409.92 199.13 409.93 198.64
    Q 410.41 178.83 407.10 160.29
    Q 405.76 152.75 402.54 143.77
    Q 402.36 143.28 401.88 143.50
    C 388.81 149.48 378.13 159.80 370.25 171.54
    Q 357.45 190.62 350.86 216.24
    Q 350.62 217.17 351.54 216.89
    Z
    M 392.60 382.65
    A 0.16 0.16 0.0 0 0 392.82 382.56
    L 416.06 315.78
    A 0.88 0.87 21.8 0 0 415.59 314.69
    Q 409.63 312.00 403.31 310.14
    Q 397.32 308.38 390.59 307.46
    Q 362.69 303.65 334.79 309.83
    C 310.19 315.27 287.65 328.13 272.10 347.70
    Q 253.43 371.22 249.55 402.02
    Q 248.31 411.87 249.28 423.50
    Q 251.07 444.95 263.29 460.73
    Q 268.39 467.31 272.14 470.89
    Q 280.73 479.09 293.46 489.10
    Q 298.03 492.69 303.81 498.71
    Q 312.37 507.61 314.43 518.45
    Q 315.66 524.90 314.29 530.07
    C 311.68 539.97 302.42 547.11 292.23 547.11
    Q 279.54 547.11 266.65 541.06
    Q 257.30 536.66 253.80 534.46
    Q 243.93 528.26 234.76 521.12
    Q 234.48 520.90 234.37 521.24
    L 218.88 572.13
    A 1.35 1.35 0.0 0 0 219.19 573.45
    Q 233.74 588.63 252.89 597.50
    Q 278.58 609.41 307.64 609.07
    Q 314.43 609.00 321.38 607.88
    Q 353.17 602.76 372.55 577.83
    Q 381.08 566.85 385.73 553.29
    Q 394.64 527.30 390.39 500.82
    Q 388.78 490.82 384.63 481.75
    Q 378.72 468.81 370.33 459.95
    Q 363.05 452.27 360.77 450.26
    Q 351.13 441.77 331.92 427.79
    Q 326.10 423.56 320.93 418.55
    C 313.93 411.78 309.78 404.98 311.77 394.79
    C 313.83 384.19 322.38 376.65 332.61 374.15
    Q 345.32 371.04 359.86 373.01
    Q 377.00 375.33 392.60 382.65
    Z"
      />
      <path
        fill="current-color"
        d="
    M 544.20 209.96
    Q 573.00 212.20 590.75 213.27
    Q 596.43 213.61 597.20 218.85
    Q 629.94 441.10 661.86 658.63
    Q 661.93 659.09 661.47 659.19
    L 483.38 697.91
    A 0.30 0.30 0.0 0 1 483.01 697.60
    L 505.50 174.67
    Q 505.61 172.09 507.71 174.18
    L 543.78 209.91
    Q 543.81 209.93 544.20 209.96
    Z"
      />
    </svg>
  )
}
