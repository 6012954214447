import * as z from 'zod'

const platforms = [
  'amazon',
  'etsy',
  'shopbase',
  'shopify',
  'facebook_marketplace',
  'ebay',
  'woocommerce',
  'tiktok',
] as const

const PlatformEnum = z.enum(platforms)
const PLATFORMS = PlatformEnum.Enum

type Platform = z.infer<typeof PlatformEnum>

export { PLATFORMS, type Platform }
