import Axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import { toast } from '@/components/ui/toast'
import { cookies, TOKENS } from '@/config/cookies'
import { env } from '@/config/env'

import type { User } from '@/modules/users/types/user'
import type { ApiResponse } from '@/types/api'

export function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = cookies.getToken(TOKENS.TOKEN)
  config.headers.Accept = 'application/json'
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

export const api = Axios.create({
  baseURL: env.API_URL,
})

export const apiRaw = Axios.create({
  baseURL: env.API_URL,
})

export const godStorageApi = Axios.create({
  baseURL: env.GOD_STORAGE_API_URL,
})

godStorageApi.interceptors.request.use(authRequestInterceptor)
godStorageApi.interceptors.response.use((response) => {
  return response.data
})

apiRaw.interceptors.request.use(authRequestInterceptor)

type RefreshTokenResponse = AxiosResponse<Awaited<ApiResponse<User>>>

api.interceptors.request.use(authRequestInterceptor)
api.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error) => {
    const originalRequest = error.config
    const status = error.response?.status
    let message =
      error.response?.data?.message || error.response?.message || error.message

    if (typeof message === 'object') {
      message = JSON.stringify(message)
    }

    const unauthorized =
      !originalRequest._retry &&
      status === 401 &&
      // FIXME: Có 1 case khi login sai password nhưng server trả về 401, nên cần phải check message
      message.includes('Bad token') &&
      // FIXME: check if api url include "asin-warehouses/export"
      !originalRequest.url?.includes('asin-warehouses/export')

    if (unauthorized) {
      try {
        const refreshToken = cookies.getToken(TOKENS.REFRESH_TOKEN)
        if (!refreshToken) {
          throw new Error()
        }

        const { data } = (await Axios.post(
          `${env.API_URL}/auth/refresh-token`,
          null,
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          },
        )) as RefreshTokenResponse

        if (data.data) {
          const { token, refreshToken } = data.data
          originalRequest.headers.Authorization = `Bearer ${token}`
          cookies.setToken(TOKENS.TOKEN, token)
          cookies.setToken(TOKENS.REFRESH_TOKEN, refreshToken)
          return api(originalRequest)
        } else {
          throw new Error()
        }
      } catch (e) {
        cookies.clearTokens()
        const err = new Error('AUTH_REQUIRED')
        console.error(err)
        // check if the current url is not login page
        // if (!window.location.pathname.includes('auth/login')) {
        //   window.location.href = '/auth/login'
        // }
        return Promise.reject(err)
      }
    }

    toast({
      description: message,
      variant: 'error',
    })

    return Promise.reject(error)
  },
)
