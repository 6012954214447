import * as React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import 'nprogress/nprogress.css'
import 'react-medium-image-zoom/dist/styles.css'
import App from './app'

const root = document.getElementById('root')

window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})

createRoot(root!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
